<template>
  <v-container class="mt-5">
    <v-row>
      <v-col cols="12" md="4">
        <v-select
          dense
          outlined
          label="Filter by year"
          placeholder="Filter by year"
          ref="filterYear"
          v-model="filterYear"
          @change="filter"
          clearable
          @click:clear="fetch()"
          :items="yearList.years"
          width="10"
        >
          <template v-slot:prepend-inner>
            <v-icon size="16" class="mb-n6"> mdi-filter </v-icon>
          </template>
        </v-select>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="this.filterYear ? this.filteredCpds : cpdPoints"
      sort-by="calories"
      class="mt-5 elevation-1"
      dense
    >
      <template v-slot:item.actions="{ item }">
        <v-btn
          outlined
          x-small
          color="secondary"
          :href="editEvent(encodeRoute(item.certificateNo))"
          target="_blank"
        >
          view
          <v-icon color="secondary" right small> mdi-arrow-right </v-icon>
        </v-btn>
      </template>
      <template v-slot:body.append>
        <tr style="height: 60px; color: white; background-color: black">
          <td></td>
          <td colspan="4" rowspan="4">
            <span class="text-body-1"> Total CPD Acquired</span>
          </td>
          <td></td>
          <td colspan="4" rowspan="4">
            <span class="text-body-1">{{ total }} </span>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { AuthService } from "../../auth";
import RouterMixin from "../../../router/RouterMixin";

export default {
  name: "CpdList",

  data: () => ({
    dialog: false,
    dialogDelete: false,
    currentYear: new Date().getFullYear(),
    filterYear: "",
    filteredCpds: [],
    total: 0,
  }),

  mixins: [RouterMixin],

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Cpd/getCPDAWARDS", v.user.profile.MemberNo);
    });
  },

  computed: {
    user() {
      return AuthService.user;
    },

    cpdPoints() {
      return this.$store.getters["Cpd/cpdGetters"]("cpd");
    },

    headers() {
      return [
        {
          text: "Member No",
          value: "memberNo",
          align: "start",
          sortable: true,
        },
        {
          text: "Event Name",
          value: "description",
          align: "start",
          sortable: true,
        },
        {
          text: "Provider",
          value: "provider",
          align: "start",
          sortable: true,
        },
        {
          text: "Location",
          value: "location",
          align: "start",
          sortable: true,
        },
        {
          text: "Start Date",
          value: "eventDate",
          align: "start",
          sortable: true,
        },
        { text: "End Date", value: "endDate", align: "start", sortable: true },
        {
          text: "CPD",
          value: "pointAwarded",
          align: "start",
          sortable: true,
        },
        {
          text: "Event Code",
          value: "eventCode",
          align: "start",
          sortable: true,
        },
        {
          text: "Certificate",
          value: "certificateNo",
          align: "start",
          sortable: true,
        },
        { text: "Actions", value: "actions", align: "start", sortable: true },
      ];
    },

    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },

    yearList() {
      if (this.cpdPoints.length !== 0) {
        let startYear = this.cpdPoints[0].period;
        for (let i = 1; i < this.cpdPoints.length; i++) {
          if (this.cpdPoints[i].period < startYear) {
            startYear = this.cpdPoints[i].period;
          }
        }
        const years = [];
        for (let year = startYear; year <= this.currentYear; year++) {
          years.push(year);
        }
        return { startYear: parseInt(startYear), years: years.reverse() };
      } else {
        return 0;
      }
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    cpdPoints() {
      this.total = this.cpdPoints.reduce((acc, currValue) => {
        return acc + currValue.pointAwarded;
      }, 0);
    },
  },

  methods: {
    editEvent(event) {
      return process.env.VUE_APP_ALMANAC + `cert-verify/${event}`;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },

    filter() {
      this.filteredCpds = this.cpdPoints.filter((item) => {
        return item.period === this.filterYear.toString();
      });
      this.total = this.filteredCpds.reduce((acc, currValue) => {
        return acc + currValue.pointAwarded;
      }, 0);
    },

    fetch() {
      this.$store.dispatch("Cpd/getCPDAWARDS", this.user.profile.MemberNo);
    },
  },
};
</script>

<style scoped></style>

import { render, staticRenderFns } from "./DashboardLayout.vue?vue&type=template&id=4acf3b26&scoped=true"
import script from "./DashboardLayout.vue?vue&type=script&lang=js"
export * from "./DashboardLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4acf3b26",
  null
  
)

export default component.exports
<template>
    <v-container>
      <v-row
        v-if="
          typeof document !== 'undefined' && document.length !== 0 && !loading
        "
      >
        <v-col cols="12" md="8" offset-md="2">
          <v-card class="my-5">
            <v-card-title>
              <v-spacer />
              <v-btn
                small
                color="secondary"
                @click="downloadFile(document, fileName)"
              >
                <v-icon left>mdi-download</v-icon>
                Download
              </v-btn>
            </v-card-title>
  
            <pdf
              style="width: 100%"
              :src="`data:application/pdf;base64,${document}`"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else-if="showNotFoundMessage">
        <v-col cols="12" md="8" offset-md="2">
          <v-card class="my-5" flat>
            <v-card-text class="text-center">
              <h2>Oops,</h2>
              something went wrong.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" md="8" offset-md="2">
          <v-card class="my-5">
            <v-card-title>
              <v-spacer />
              <v-skeleton-loader type="button" />
            </v-card-title>
  
            <div>
              <v-skeleton-loader type="card" />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import pdf from "vue-pdf";
  import RouterMixin from "../../../router/RouterMixin";
  import FileMixin from "../../../mixins/FileMixin";
  import { AuthService } from "../../auth";
  
  export default {
    name: "Document",
    components: { pdf },
    mixins: [RouterMixin, FileMixin],
    data: () => ({
      loading: true,
      showNotFoundMessage: false,
    }),
  
    beforeRouteEnter(to, from, next) {
      next((v) => {
        v.$store.dispatch(
          "License/membershipCertificate",
          v.decodeRoute(v.$route.params.code)
        );
      });
    },
  
    computed: {
      document() {
        return this.$store.getters["License/licenseGetters"](
          "membershipCertificate"
        )[0];
      },
      user() {
        return AuthService.user;
      },
      fileName() {
        const certName = "Membership-Certificate";
        const {
          profile: { FirstName, CustomerNo },
        } = this.user;
        return `${FirstName}-${CustomerNo}-${certName}`;
      },
      goodStandingReasons() {
        return this.user.profile?.goodStandingMessage?.split("\\");
      },
    },
    watch: {
      document(newValue) {
        this.loading = false;
        if (!newValue) {
          this.showNotFoundMessage = true;
        } else {
          this.showNotFoundMessage = false;
        }
      },
    },
  };
  </script>
  
export default {
  LICENSE_APPLICATIONS: "license",
  PROFILE: "license/profile",
  LICENSE: (code) => `license${code ? "/" + code : ""}`,
  CHECKLIST: (cat) => `license/checklist/${cat}`,
  CERTIFICATE_OF_GOOD_STANDING: (code) =>
    `license/good_standing_certificate/${code}`,
  MEMBERSHIP_CERTIFICATE: (code) =>
    `license/membership_certificate/${code}`,
  LICENSE_UPDATE: `license/update`,
  APPLICATION: (code) => `license/application/${code}`,
  PROFORMA: (code) => `license/proforma/${code}`,
};

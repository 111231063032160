import call from "../../service/http";
import licenseConstants from "./LicenseConstants";
import { helper } from "../../utils";

export default {
  namespaced: true,
  state: {
    licenseApplications: [],
    profile: [],
    application: {
      description: "",
      profileID: "",
      applicationDate: "",
      amount: 0,
      memberCategory: "",
      startDate: "",
      countryCode: "",
      county: "",
      city: "",
      postalCode: "",
      physicalLocation: "",
      phoneNo: "",
      email: "",
      practiceName: "",
      practitionerSurname: "",
      practitionerOtherNames: "",
      practitionerPhone: "",
      practitionerEmail: "",
      practitionerAddress: "",
    },
    certificateOfGoodStanding: "",
    membershipCertificate: "",
    checklist: [],
    licenses: [],
    licenseApplication: "",
    proforma: "",
  },
  mutations: {
    MUTATE: (state, payload) => {
      state[payload.state] = payload.data;
    },

    SET_APPLICATION: (state, payload) => {
      state.application = payload;
    },
  },
  getters: {
    licenseGetters: (state) => (val) => state[val],
  },
  actions: {
    getLicenseApplications: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", licenseConstants.LICENSE_APPLICATIONS)
        .then((res) => {
          commit("MUTATE", {
            state: "licenseApplications",
            data: res.data.data,
          });
        })
        .catch((error) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: error.response.data.message,
          });
        });
    },

    getLicenseProfile: ({ commit }) => {
      commit("SET_LOADING", true, { root: true });
      call("get", licenseConstants.PROFILE)
        .then((res) => {
          commit("MUTATE", {
            state: "profile",
            data: res.data.data[0],
          });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: error.response.data.message,
          });
        });
    },

    submitApplication: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("post", licenseConstants.LICENSE(null), data)
        .then((res) => {
          commit("SET_APPLICATION", res.data.data);
          commit("SET_LOADING", false, { root: true });
          Event.$emit("License-saved", res.data.data);
        })
        .catch((error) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: error.response.data.message,
          });
        });
    },

    getChecklists: ({ commit }, data) => {
      commit("SET_LOADING", true, { root: true });
      call("get", licenseConstants.CHECKLIST(data))
        .then((res) => {
          commit("MUTATE", { state: "checklist", data: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: error.response.data.message,
          });
        });
    },

    certificateOfGoodStanding({ commit }, data) {
      commit("SET_LOADING", true, { root: true });
      call("get", licenseConstants.CERTIFICATE_OF_GOOD_STANDING(data))
        .then((res) => {
          commit("MUTATE", {
            state: "certificateOfGoodStanding",
            data: res.data.data,
          });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: error.response.data.message,
          });
        });
    },

    membershipCertificate({ commit }, data) {
      commit("SET_LOADING", true, { root: true });
      call("get", licenseConstants.MEMBERSHIP_CERTIFICATE(data))
        .then((res) => {
          commit("MUTATE", {
            state: "membershipCertificate",
            data: res.data.data,
          });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: error.response.data.message,
          });
        });
    },

    updateLicence: ({ commit, dispatch }, data) => {
      commit("SET_LOADING", true);
      call(
        "post",
        licenseConstants.LICENSE_UPDATE,
        helper.prepareFormData(data)
      )
        .then((res) => {
          commit("MUTATE", { state: "licenses", data: res.data.data });
          commit("SET_LOADING", false, { root: true });
          dispatch("getApplication", data.licenseApplicationNo);
          commit("SET_ALERT", {
            status: "success",
            message: res.response.data.message,
          });
        })
        .catch((error) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: error.response.data.message,
          });
        });
    },

    getApplication: ({ commit }, no) => {
      commit("SET_LOADING", true);
      call("get", licenseConstants.APPLICATION(no))
        .then((res) => {
          commit("MUTATE", {
            state: "licenseApplication",
            data: res.data.data[0],
          });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: error.response.data.message,
          });
        });
    },

    getLicence: ({ commit }, code) => {
      commit("SET_LOADING", true);
      call("get", licenseConstants.LICENSE(code))
        .then((res) => {
          commit("MUTATE", { state: "licenses", data: res.data.data });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: error.response.data.message,
          });
        });
    },

    getProforma: ({ commit }, no) => {
      commit("SET_LOADING", true);
      call("get", licenseConstants.PROFORMA(no))
        .then((res) => {
          commit("MUTATE", { state: "proforma", data: res.data.data[0] });
          commit("SET_LOADING", false, { root: true });
        })
        .catch((error) => {
          commit("SET_LOADING", false, { root: true });
          commit("SET_ALERT", {
            status: "error",
            message: error.response.data.message,
          });
        });
    },
  },
};
